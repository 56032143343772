(function() {

  var GeoHelpers = {
    isArcGISDataset: function(view) {
      return !_.isNull(_.get(view, 'metadata.custom_fields.Basic.Source', null));
    },
    isGeoDataset: function(view) {
      return !_.isNull(_.get(view, 'metadata.geo', null));
    },
    isArcGISOrGeoDataset: function(view) {
      return this.isArcGISDataset(view) || this.isGeoDataset(view);
    },
    isGeoExportSupported: function(view) {
      const geospatialColumnTypes = /^(point|line|polygon|multipoint|multiline|multipolygon)$/i;
      const hasSingleGeoColumn = _.filter(
        _.get(view, 'columns', []),
        col => geospatialColumnTypes.test(col.dataTypeName) && ((col.flags || []).indexOf('hidden') === -1)
      ).length == 1;
      return hasSingleGeoColumn;
    }
  };

  if (blist.inBrowser) {
    window.GeoHelpers = GeoHelpers;
  } else {
    module.exports = GeoHelpers;
  }
})();
