import 'frontend/public/javascripts/legacyModules/jqModal';
import isFunction from 'lodash/isFunction';

$(function() {
  var getModal = function(selector) {
    var $modal = $('#modals ' + selector);
    if ($modal.length === 0) {
      // didn't find a modal; grab it out of templates and initialize it.
      // Note that we might not have a modal template on some pages - if
      // this happens, we fall back to plain browser alerts.
      $modal = $($('#templates').contents().text()).filter(selector);
      $modal.appendTo('#modals');
      $modal.jqm({
        trigger: false
      });
    }
    return $modal;
  };

  const originalAlert = window.alert;
  window.alert = function(msg) {
    const modal = getModal('#jqmAlert');
    if (modal.length) {
      modal.jqmShow().find('.alertMessage').text(msg);
    } else {
      originalAlert.apply(this, arguments);
    }
  };

  window.prettyConfirm = function(msg, yes, no) {
    const modal = getModal('#jqmAlert');
    if (modal.length) {
      modal.jqmShow().
        find('.confirmMessage').text(msg).end().
        find('.button.yes').click(yes).end().
        find('.button.no').click(no).end();
    } else {
      if (confirm(msg)) {
        // To more closely match the async nature of jqm, invoke
        // the callbacks async.
        if (isFunction(yes)) {
          setTimeout(yes, 0);
        }
      } else {
        if (isFunction(no)) {
          setTimeout(no, 0);
        }
      }
    }
  };
});
