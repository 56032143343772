(function() {

var createDatasetFromView = function(view) {
  return new Dataset(view);
};

if (blist.inBrowser) {
  window.createDatasetFromView = createDatasetFromView;
} else {
  module.exports = createDatasetFromView;
}

})();
